























import mixins from 'vue-typed-mixins'
import Employees from '@/calendesk/sections/section/employees/mixins/Employees'
import EmployeeCard from '@/calendesk/sections/section/employees/components/EmployeeCard.vue'
export default mixins(Employees).extend({
  name: 'Employees2',
  components: { EmployeeCard }
})
